import { createContext, useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types';
import { UserContext } from "../user";

export const CalendarContext = createContext()
export const CalendarConsumer = CalendarContext.Consumer

const CalendarContextProvider = ({ children }) => {
  const { apiKey } = useContext(UserContext)
    const [timeslots, setTimeslots] = useState([])
    const hostB64 = btoa(window.location.host); 

    
  useEffect(() => {  
    
    if(!apiKey) {
      return
    }
    
    fetch(`https://concord.triba.co/calendar?studio_id=15`, {
      headers: {
        Authorization: `Bearer ${hostB64}`,
        "x-api-key" : apiKey
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
            setTimeslots(result);
        },
        (error) => {
          console.error(error);
        }
      )
  }, [apiKey])
    return (
        <CalendarContext.Provider value={{
            timeslots
        }}>
            {children}
        </CalendarContext.Provider>
    );
}

CalendarContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export default CalendarContextProvider