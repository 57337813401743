import styled, { keyframes } from "styled-components";
import { useEffect } from 'react'
import './lightbox.css';
const Wrapper = styled.section`
            animation: .4s ${props => props.animation} ease-in;
        `

function Lightbox({ show, setShow, coords, children }) {
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                setShow(false)
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [setShow]);

    if (show) {
        const wrapperCoors = document.getElementById('triba-calendar-wrapper').getBoundingClientRect()
        const left = coords.left - wrapperCoors.x
        const top = coords.top - wrapperCoors.y
        const animation = keyframes`
            from {
                background: #ddecff;
                left: ${left}px;
                top: ${top}px;
                width: ${coords.width}px;
                height: ${coords.height}px;
            }
        `;

        return (
            <Wrapper id='triba-lightbox' animation={animation}>
                <span className='triba-close-button' onClick={() => { 
                    setShow(null)
                }}></span>
                {children}
            </Wrapper >
        )
    }

    return null
}

export default Lightbox;