import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import UserContextProvider from './contexts/user/user-context';
import ClassContextProvider from './contexts/class/class-context'
import CustomizeContextProvider from './contexts/customize/customize-context'
import CalendarContextProvider from './contexts/calendar/calendar-context'
import ApiContextProvider from './contexts/api/api-context'

import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <UserContextProvider>
      <CustomizeContextProvider>
        <CalendarContextProvider>
          <ClassContextProvider>
              <ApiContextProvider>
                <App />
              </ApiContextProvider>
          </ClassContextProvider>
        </CalendarContextProvider>
      </CustomizeContextProvider>
    </UserContextProvider>
  </React.StrictMode>,
  document.getElementById('triba-calendar')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
