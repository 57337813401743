import { createContext, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import baseurl from '../../utilities/api'

export const CustomizeContext = createContext()
export const CustomizeConsumer = CustomizeContext.Consumer

const CustomizeContextProvider = ({ children }) => {
    const [customize, setCustomize] = useState(() => {
        const calendarElement = document.getElementById('triba-calendar');
        return JSON.parse(calendarElement?.dataset?.customize) || null;
    });

    useEffect(() => {
        const calendarElement = document.getElementById('triba-calendar');
        if (!calendarElement) return;

        // Create a MutationObserver to watch for changes
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'data-customize') {
                    const newCustomize = JSON.parse(calendarElement.dataset.customize || null);
                    setCustomize(newCustomize);
                }
            });
        });

        // Start observing the element for attribute changes
        observer.observe(calendarElement, {
            attributes: true,
            attributeFilter: ['data-customize'] // only watch the data-customize attribute
        });

        // Cleanup observer on component unmount
        return () => observer.disconnect();
    }, []); // Empty dependency array since we only want to set up the observer once

    useEffect(() => {
        // Only fetch if no initial value was provided
        if (!customize) {
            const hostB64 = btoa(window.location.host); 
            fetch(`${baseurl}/api/customize`, {
                headers: {
                    Authorization: `Bearer ${hostB64}`
                }
            })
            .then(response => response.json())
            .then(data => setCustomize(data.customize.fontColor))
            .catch(error => console.error(error));
        }
    }, []); // Add dependency array to prevent infinite loop

    return (
        <CustomizeContext.Provider 
            value={{
                customize
            }}
        >
            {children}
        </CustomizeContext.Provider>
    );
}

CustomizeContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export default CustomizeContextProvider