import { useContext } from "react";
import { ClassContext } from "../../contexts/class";
import { CustomizeContext } from "../../contexts/customize";

function Calendar(props) {
    const { classes, date, setSelectClass } = props
    const dateOptions = { weekday: 'short', month: 'short', day: 'numeric' };
    const { classtype } = useContext(ClassContext)
    const { customize } = useContext(CustomizeContext)
    const {fontColor} = customize
    

    let customStyle = {}
    
    if(fontColor) {
        // Convert hex to RGB and determine brightness
        const r = parseInt(fontColor.substring(0, 2), 16);
        const g = parseInt(fontColor.substring(2, 4), 16);
        const b = parseInt(fontColor.substring(4, 6), 16);
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;

        // For light text, create a darker background
        // For dark text, create a lighter background
        let bgR, bgG, bgB;
        let opacity;
        
        if (brightness > 155) {
            // Light text - create darker background
            bgR = Math.max(0, r - 160);
            bgG = Math.max(0, g - 160);
            bgB = Math.max(0, b - 160);
            // Scale opacity based on brightness: 1 for white (255), scaling down to 0.15 for darker colors
            opacity = Math.min(1, (brightness - 155) / 100);
        } else {
            // Dark text - create lighter background
            bgR = Math.min(255, r + 160);
            bgG = Math.min(255, g + 160);
            bgB = Math.min(255, b + 160);
            opacity = 0.15;  // Keep subtle for dark colors
        }

        customStyle = {
            backgroundColor: `rgba(${bgR}, ${bgG}, ${bgB}, ${opacity})`,
            color: `#${fontColor}`
        }
    } 

    if (classes.length === 0) {
        return null
    }
    
    return (
        <ul>
            {Array.apply(null, { length: 7 }).map((n, i) => {
                const calendarDate = date.addDays(i)
                return (
                    <li key={calendarDate.getDate()}>
                        <span 
                            className='calendardate'
                            style={customStyle}
                        >
                            {calendarDate.toLocaleDateString("en-GB", dateOptions)}
                        </span>
                        {classes[calendarDate.getDay()].map((tribaClass) => {
                            if (classtype === 'studio' && tribaClass.studio_price_state === 0) {
                                return null
                            }

                            if (classtype === 'stream' && tribaClass.stream_price_state === 0) {
                                return null
                            }

                            if (new Date(tribaClass.start_date.replace(/\s/, 'T')).getTime() < calendarDate.setHours(23, 59, 59, 999) && new Date(tribaClass.end_date.replace(/\s/, 'T')).getTime() > calendarDate.setHours(0, 0, 0, 0)) {
                                const time = tribaClass.start_time.split(':')
                                const classDate = calendarDate.setHours(time[0], time[1])
                                const classdate = new Date(classDate)
                                const year = classdate.getFullYear();
                                let month = classdate.getMonth()+1;
                                let dt = classdate.getDate();
                                let hour = classdate.getHours()
                                let minute = classdate.getMinutes()

                                if (dt < 10) {
                                    dt = '0' + dt;
                                }

                                if (month < 10) {
                                    month = '0' + month;
                                }

                                if (hour < 10) {
                                    hour = '0' + hour;
                                }

                                if (minute < 10) {
                                    minute = '0' + minute;
                                }
                                
                                const datestring = `${year}-${month}-${dt} ${hour}:${minute}`;
                                const disabled = classdate < new Date() ? 'disabled' : ''
                                tribaClass.disabled = disabled

                                return (
                                    <div 
                                        key={`${tribaClass.classid}${tribaClass.tid}`} 
                                        className={`calendarClass ${tribaClass.category} ${classtype} ${disabled}`} 
                                        data-date={datestring} 
                                        disabled={disabled} 
                                        onClick={(e) => { 
                                            setSelectClass(e, tribaClass) 
                                        }}
                                        style={customStyle}
                                    >
                                        <span>{tribaClass.name}</span>
                                        <span className='calendarClass--time'>{tribaClass.start_time} - {tribaClass.end_time}</span>
                                        <span>{tribaClass.firstname} {tribaClass.lastname}</span>
                                        <img alt={tribaClass.name} src={tribaClass.image_url} />
                                    </div>
                                )
                            } else {
                                return null
                            }
                        })}
                    </li>
                )
            })}
        </ul>
    )
}

export default Calendar;