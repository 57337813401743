import { createContext, useState, useEffect } from "react";
import PropTypes from 'prop-types';

export const UserContext = createContext()
export const UserConsumer = UserContext.Consumer

const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [apiKey, setApiKey] = useState(null)

    useEffect(() => {
        // Get API key from DOM element
        const calendarElement = document.getElementById('triba-calendar');
        const apiKey = calendarElement?.dataset?.apiKey || '';
        setApiKey(apiKey);

        // Existing session check logic
        function getSession() {            
            try {
                const tribaToken = document.cookie
                    .split('; ')
                    .find(row => row.startsWith('triba_token='))
                    ?.split('=')[1];
                if (tribaToken) {
                    const payload = JSON.parse(atob(tribaToken.split('.')[1]));                    
                    setUser({id: payload.user_id, email: payload.email, stripe_account_id : payload.stripe_account_id});
                } else {
                    setUser(null);
                }
            } catch (error) {
                console.error("Error parsing JWT token:", error);
                setUser(null);
            }
        }

        getSession();
    }, []);

    const logout = () => {
        setUser(null);
        document.cookie = 'triba_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }

    return (
        <UserContext.Provider 
            value={{ 
                user, 
                setUser, 
                logout,
                apiKey 
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

UserContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export default UserContextProvider