import { createContext, useContext, useState } from "react";
import { UserContext } from "../user";
import PropTypes from 'prop-types';

export const ApiContext = createContext();

const baseurl = process.env.NODE_ENV === 'production' ? 'https://api.triba.tech' : 'https://localhost:8443';
const hostB64 = btoa(window.location.host);

const ApiContextProvider = ({ children }) => {
    const { apiKey } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const checkCapacity = async (booking) => {    
        setLoading(true);
        setError(null);
        
        try {
            const response = await fetch(`https://concord.triba.co/bookings/capacity?` + new URLSearchParams(
                booking
            ), {
                headers: {
                    "x-api-key": apiKey
                }
            });
            
            if (!response.ok) throw new Error(response);
            const data = await response.json();
            return data;
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const userExists = async (email) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`https://concord.triba.co/customers/exists?` + new URLSearchParams({
                email: email
            }), {
                headers: {
                    "x-api-key": apiKey
                }
            });

            if (!response.ok) throw new Error(response);
            const data = await response.json();
            return data;
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    // Add other API methods here...

    return (
        <ApiContext.Provider value={{
            loading,
            error,
            checkCapacity,
            userExists,
            // ... other methods
        }}>
            {children}
        </ApiContext.Provider>
    );
};

ApiContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ApiContextProvider; 