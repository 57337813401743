import { useContext, useState, useEffect } from 'react'
import Date from '../date/date'
import CalendarIcon from '../icons/calendar/calendar'
import UserIcon from '../icons/user/user'
import Clock from '../icons/clock/clock'
import { ClassContext } from '../../contexts/class';
import './class-information.css'

function ClassInformation(props) {
    const { tribaClass } = useContext(ClassContext);
    const { capacity, success } = props
    const [imageLoaded, setImageLoaded] = useState(false);
    const [shouldLoadImage, setShouldLoadImage] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShouldLoadImage(true);
        }, 100);

        return () => clearTimeout(timer);
    }, []);

    let classname = ''
    let s = 's'
    let capacityParagraph = null

    if (success) {
        classname = 'animate'
    }

    if (capacity === 1) {
        s = ''
    }

    if (success && capacity === 2) {
        s = ''
    }

    if (capacity) {
        capacityParagraph =
            <p>
                Only
                <span className='capacity_animate_wrapper'>
                    <span className={`capacity_animate ${classname}`}>
                        <span>{capacity}</span>
                        <span>{capacity - 1}</span>
                    </span>
                </span>
                spot{s} left
            </p>

        if (capacity > 10 || capacity === 0) {
            capacityParagraph = null
        }
    }

    return (
        <div id='triba-booking-form-class-info'>
            <div className="image-loader" style={{ display: imageLoaded ? 'none' : 'block' }} />
            {shouldLoadImage && (
                <img 
                    src={tribaClass.image_url} 
                    alt={`The poster of ${tribaClass.name}`} 
                    onLoad={() => setImageLoaded(true)}
                    className={imageLoaded ? 'loaded' : ''}
                />
            )}
            <section>
                <h2>{tribaClass.name}</h2>
                {capacityParagraph}
                <p><CalendarIcon /><strong><Date>{tribaClass.selectedDate}</Date></strong></p>
                <p><Clock /><strong>{tribaClass.start_time} - {tribaClass.end_time}</strong></p>
                <p><UserIcon /><strong>{tribaClass.firstname} {tribaClass.lastname}</strong></p>
            </section>
        </div>
    )
}

export default ClassInformation;