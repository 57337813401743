import { useState, useContext } from 'react'
import { ApiContext } from '../../../contexts/api/api-context'
import { UserContext } from '../../../contexts/user';
import Loader from '../../loader/loader';

function EmailCheck({setView}) {
    const [email, setEmail] = useState(undefined)
    const { userExists, loading, error } = useContext(ApiContext);
    const { user, setUser } = useContext(UserContext);

    const userCheck = async (e) => {
        e.preventDefault()
        setUser({ email, ...user })
       
        try {
            const existingUser = await userExists(email)
            if (existingUser.email === email) {
                setView('login')
            }
            if(existingUser.email === null) {
                setView('newuser')
            } 
        } catch (error) {
            console.log(error);
        }
    }

    if (!loading) {
        return (
            <form id='check-email' onSubmit={userCheck}>
                <div className='inputWrapper'>
                    <label>What is your email address?</label>
                    <input onChange={(e) => setEmail(e.target.value)} id='triba-email' type='email' placeholder="your@email.co" autoFocus />
                </div>
                <p>{error}</p>
                <button type='submit'>Next</button>
            </form>
        )
    }

    return <Loader />

}

export default EmailCheck;