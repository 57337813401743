import { useContext, useEffect, useState } from 'react'
import { ClassContext } from '../../../contexts/class';
import { UserContext } from '../../../contexts/user';
import PassIcon from '../../icons/pass/pass';
import { createBooking, payWithPass } from '../../../utilities/api';
import Loader from '../../loader/loader';

function Pass({ setView }) {
    const { user, setUser } = useContext(UserContext);
    const { tribaClass } = useContext(ClassContext);
    const [passes, setPasses] = useState([])
    const [selectedPass, setSelectedPass] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    useEffect(() => {
        let passes = [];
        for (const pass of user.passes) {
            if (pass.valid) {

                switch (tribaClass.classtype) {
                    case 'stream':
                        if (pass.online_price_state === 1) {
                            passes.push(pass)
                        }
                        break;
                    case 'studio':
                        if (pass.studio_price_state === 1) {
                            passes.push(pass)
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        setSelectedPass(passes[0].id)
        setPasses([passes[0]])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // TODO: logout func should be in user context
    const logout = () => {
        setUser(null);
        setView('emailcheck')
        document.cookie = 'triba=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    const bookClass = async (e) => {
        e.preventDefault()
        setLoading(true)
        let bookingObject = {
            userid: user.id,
            // TODO: Concord requires paymentIntent. Is Client Secret needed?
            clientSecret: `pass_${selectedPass}`,
            paymentIntent: `pass_${selectedPass}`,
            amount: 0,
            ...tribaClass
        }

        let errorMessage = `There's been a problem taking payment with your pass. Please try later`

        try {
            await payWithPass(selectedPass, user.id)
            errorMessage = `There's been a problem saving your place - please get in touch.`
            await createBooking(bookingObject)
            setLoading(false)
            setView('success')
        } catch (error) {
            setLoading(false)
            setError(errorMessage)
        }

        try {

        } catch (error) {

        }
    }

    let passSelector
    let remaining = null

    if (passes.length > 0) {
        let singlePass = passes[0]
        if (singlePass.amount_of_classes > 1) {
            remaining = `${singlePass.classes_remaining} classes remaining`
        }
        passSelector = <p>{passes[0].name}<br /><span className='transparent'>{remaining}</span></p>
        if (passes.length > 1) {

            passSelector = <select className="triba-select" value={selectedPass} onChange={(e) => { setSelectedPass(e.target.value) }}>
                {passes.map((pass, i) => {
                    return (
                        <option key={`tribapass-${i}`} value={pass.id}>
                            {pass.name}
                        </option>
                    )
                })}
            </select>
        }
    }

    if (loading) {
        return <Loader />
    }

    return (
        <form id='triba-paymentform' onSubmit={bookClass}>
            <div id='pass-icon-wrapper'>
                <PassIcon />
                {passSelector}
            </div>
            <p>Hi, <strong>{user.firstname}</strong></p>
            <p><span>{user.email}</span></p>
            <button className='triba-tertiary' onClick={() => { logout() }}>Not you?</button>
            <p>This class is included in your Pass.</p>
            <p>{error}</p>
            <button type='submit'>Book Class</button>
        </form >
    )
}

export default Pass;