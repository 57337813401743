import { useState, useContext } from 'react'
import {login} from '../../../utilities/api'
import { UserContext } from '../../../contexts/user';
import Loader from '../../loader/loader';
import { ClassContext } from '../../../contexts/class';

function Login(props) {
    const [password, setPassword] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState(undefined)
    const { user, setUser } = useContext(UserContext);
    const { tribaClass } = useContext(ClassContext)
    const { setView, setValidMembership } = props

    const getUser = async (e) => {
        e.preventDefault()
        setLoading(true)
        
        try {
            const result = await login(user.email, password)
        if (!result.user) {
            setMessage('We do not recognise those credentials')
            setLoading(false)
        } else {
            if (result.token) {
                document.cookie = `triba_token=${result.token}; path=/; max-age=86400; SameSite=Strict`;
            }
            
            setUser(result.user)
            let validMember = false;
            let validPass = false;

            let { user } = result
            if(tribaClass.type === 1) {
                setView('payment')
                return
            }

            // if (user.memberships.length > 0) {
            //     for (const membership of user.memberships) {
            //         const now = Date.now()
            //         if (membership.cancelled !== null) {
            //             if (membership.cancelled < now) {
            //                 continue
            //             }
            //         }
            //         for (const calclass of membership.classes) {
            //             if (calclass.type === tribaClass.classtype) {
            //                 if (calclass.class_id === tribaClass.id || calclass.class_id === -1) {
            //                     validMember = true
            //                     setValidMembership(membership)
            //                     break
            //                 }
            //             }
            //         }


            //         if (validMember) {
            //             break
            //         }
            //     }
            // }

            // if (!validMember) {
            //     if (user.passes.length > 0) {
            //         for (const pass of user.passes) {
            //             if (pass.valid) {
            //                 const passExpiry = new Date(pass.expiry_date).setUTCHours(23,59,59,999);
            //                 const classDate = new Date(tribaClass.selectedDate);

            //                 if (passExpiry < classDate) {
            //                     break
            //                 }
            //                 switch (tribaClass.classtype) {
            //                     case 'stream':
            //                         if (pass.online_price_state === 1) {
            //                             validPass = true
            //                         }
            //                         break;
            //                     case 'studio':
            //                         if (pass.studio_price_state === 1) {
            //                             validPass = true
            //                         }
            //                         break;
            //                     default:
            //                         break;
            //                 }
            //             }

            //             if (validPass) {
            //                 break
            //             }
            //         }
            //     }
            // }

            if (validMember) {
                setView('member')
            } else if (validPass) {
                setView('pass')
            } else {
                setView('payment')
            }
        }
        } catch (error) {
            console.log(error);
        }
        
    }

    if (!loading) {
        return (
            <form onSubmit={getUser}>
                <div className='inputWrapper'>
                    <label>
                        Email Address?</label>
                    <input type='email' value={user.email} placeholder="your@email.co" readOnly disabled />
                </div>
                <div className='inputWrapper'>
                    <label>Password?</label>
                    <input onChange={(e) => { 
                        setPassword(e.target.value); 
                        setMessage('') 
                    }
                    } type='password' placeholder="********" autoFocus />
                </div>
                <p>{message}</p>
                <button type='submit'>Next</button>
            </form>
        )
    }

    return (<Loader />)

}

export default Login;