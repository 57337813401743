const baseurl = process.env.NODE_ENV === 'production' ? 'https://api.triba.tech' : 'https://localhost:8443';
export default baseurl;
const hostB64 = btoa(window.location.host);

const calendarElement = document.getElementById('triba-calendar');
const apiKey = calendarElement?.dataset?.apiKey || '';

export const session = async () => {
    const url = new URL(`${baseurl}/api/auth/session`)
    const session_token = window.sessionStorage.getItem("triba");
    const params = { session_token }
    url.search = new URLSearchParams(params).toString(); 

    return fetch(url, {
        headers: {
            Authorization: `Bearer ${hostB64}`
        }
    })
    .then((res) => {
        if (res.ok) {
            return res.json()
        } else {
            throw new Error(res);
        }

    })
    .then(
        (result) => {
            return result.user
        },
        (error) => {
            console.log(error)
        }
    )
};

export const checkCapacity = async (booking) => {    
    return fetch(`https://concord.triba.co/bookings/capacity?` + new URLSearchParams(
        booking
    ), {
        headers: {
            "x-api-key": apiKey
        }
    })
        .then((res) => {
            if (res.ok) {
                return res.json()
            } else {
                throw new Error(res);
            }
        })
        .then(
            (result) => {
                return result
            },
            (error) => {
                throw error
            }
        )
}

export const userExists = (email) => {
    return fetch(`https://concord.triba.co/customers/exists?` + new URLSearchParams({
        email: email
    }), {
        headers: {
            "x-api-key": apiKey
        }
    })
    .then((res) => {
        if (res.ok) {
            return res.json()
        } else {
            throw new Error(res);
        }
    })
    .then(
        (result) => {
            return result
        },
        (error) => {
            throw error
        }
    )
}

export const login = async (email, password) => {
    return fetch(`https://concord.triba.co/customers/sessions`, {
        method: 'POST',
        headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: email,
            password
        })
    })
        .then(res => res.json())
};

export const createBooking = async (booking) => {
    return fetch(`${baseurl}/api/bookings/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${hostB64}`
        },
        body: JSON.stringify(booking)
    })
        .then((res) => {
            if (res.ok) {
                return res.json()
            } else {
                throw new Error(res);
            }

        })
        .then(
            (result) => {
                return result
            },
            (error) => {
                throw error
            }
        )
}


export const createUser = async (user) => {
    return fetch(`https://concord.triba.co/customers`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
        },
        body: JSON.stringify(user)
    })
        .then((res) => {
            if (res.ok) {
                return res.json()
            } else {
                throw new Error(res);
            }
        })
        .then(
            (result) => {
                return result
            },
            (error) => {
                throw error
            }
        )
}

export const payWithPass = async (pass, userid) => {
    return fetch(`${baseurl}/api/payments/pass`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${hostB64}`
        },
        body: JSON.stringify({ pass, userid })
    })
        .then((res) => {
            if (res.ok) {
                return res.json()
            } else {
                throw new Error(res);
            }

        })
        .then((result) => {
            return result
        },
        (error) => {
            throw error
        })
}

export const createPaymentIntent = async (selectedClass, student) => {
     
    return fetch(`https://concord.triba.co/payments/intent`, {
        method: "POST",
        headers: { 
            "Content-Type": "application/json",
            'x-api-key': apiKey,
        },
        body: JSON.stringify({ 
            items: [selectedClass], 
            student,
            metadata : {
                class_id: selectedClass.class_id,
                class_date: selectedClass.selectedDate,
                timeslot_id: selectedClass.timeslot_id,
                class_type: selectedClass.type,
            }
        }),
    })
    .then((res) => res.json())
    .then((data) => {
        return data
    });
}