import { useState, useContext, useEffect } from 'react'
import BookingForm from './components/bookingform/bookingform'
import Lightbox from './components/lightbox/lightbox';
import Loader from './components/loader/loader';

import CalendarHeader from './components/calendar/header';
import Calendar from './components/calendar/calendar'
import { ClassContext } from './contexts/class';
import { CalendarContext } from './contexts/calendar/calendar-context';
import './App.css';

// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [coords, setCoords] = useState(null)
  const [date, setDate] = useState(new Date())
  const [show, setShow] = useState(false)
  const { classtype, setTribaClass } = useContext(ClassContext)
  const { timeslots } = useContext(CalendarContext)
  
  useEffect(()=> {
    if(timeslots) {
      setIsLoaded(true)
    }
  }, [timeslots])

  function changeDate(direction) {
    if (direction === 'up') {
      setDate(date.addDays(7));
      return
    }

    setDate(date.addDays(-7));
  }

  async function setSelectClass(e, tribaClass) {
    if(tribaClass.disabled === 'disabled') {
      return 
    }
    const coords = e.currentTarget.getBoundingClientRect()
    tribaClass.selectedDate = e.currentTarget.dataset.date
    tribaClass.classtype = classtype
    setCoords(coords);
    setTribaClass(tribaClass)
    setShow(true)
  }

  if (isLoaded) {
    return (
      <div className={classtype + " triba-calendar"}>
        <Lightbox show={show} setShow={setShow} coords={coords} >
          <BookingForm setShow={setShow} />
        </Lightbox>
        <CalendarHeader 
          changeDate={changeDate} 
          date={date} 
          setDate={setDate} 
        />
        <div id='triba-calendar-wrapper'>
        <Calendar 
          classes={timeslots} 
          date={date} 
          setSelectClass={setSelectClass} 
        />
        </div>
      </div >
    );
  } else {
    return (
      <Loader />
    )
  }
}

export default App;
